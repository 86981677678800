<template>
  <v-container fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="pretestAssessment.program.name"
                  label="Program"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="pretestAssessment.school.name"
                  label="School"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="pretestAssessment.assessment_tool"
                  label="Assessment Tool"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Assessment Date"
                  v-model="assessmentStatus.created_at"
                  readonly
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="pretestLearners"
              :items-per-page="5"
              class="elevation-0"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Learners</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" tile @click="savePreTestApproval" v-if="userlogin.approver">
              <v-icon>mdi-content-save</v-icon> Approve
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn to="/assessmentlist" tile>
              <v-icon>mdi-cancel</v-icon>Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { sync, dispatch } from "vuex-pathify";
export default {
  // name: "PreTestApproval",
  computed: {
    ...sync("assessment", [
      "el",
      "pretestAssessment",
      "pretestLearners",
      "assessmentStatus",
      "approvePreTest",
    ]),
    ...sync("user", ["userlogin"]),
  },
  data: () => ({
    headers: [
      { text: "Last Name", value: "learner.surname" },
      { text: "First Name", value: "learner.gname" },
      { text: "Grade Level", value: "gradeLvl" },
      { text: "Type", value: "type" },
      { text: "PreTest Value", value: "_value" },
      { text: "PreTest Level", value: "_level" },
      { text: "PostTest Value", value: "post_value" },
      { text: "PostTest Level", value: "post_level" },
    ],
    preTestAssessmentForm: {
      program_id: "",
      school_id: "",
      assessment_tool: "",
      _date: "",
    },
    preTestLearnersList: [],
  }),
  methods: {
    async savePreTestApproval() {
      this.approvePreTest.assessment_id = this.pretestAssessment.id;
      this.approvePreTest._status = "APPROVED";
      this.approvePreTest.approved = true;
      // this.approvePreTest.users_id
      await dispatch("assessment/approvePretest");
      this.el = 5;
    },
  },
};
</script>